import React, { useContext, useState, useEffect } from 'react';
import { Link, useParams, useNavigate } from 'react-router-dom';
import { InfoContext } from '../context';
import Field from '../custom/Field';
import CheckboxField from '../custom/CheckboxField';
import RadioGroup from '../custom/RadioGroup';
import { generatePdf } from '../../js/api-service';
import Spinner from '../Spinner';
import LyHealthDeclaration from './LyHealthDeclaration';
import LyHealthForm from './LyHealthForm';

export default function LyHealth() {
    const { name, personNumber, parterList, lyHealthFormData, bankIdName, setCurrentCase, session, lyHealthQuestions } = useContext(InfoContext);
    const [validCheck, setValidCheck] = useState(false);
    const [submitted, seSubmitted] = useState(false);

    let { id } = useParams();
    useEffect(() => {
        setCurrentCase(id);
    }, [])
    return (
        <>
            <LyHealthForm id={id} />
        </>
    )
}