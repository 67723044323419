import React, { useEffect, useContext, useState } from "react"
import Field from '../components/custom/Field'
import { Link, useNavigate } from 'react-router-dom'
import { InfoContext } from './context'
import FileList from './custom/FileList'
import { postMaildata, postAttachment } from '../js/api-service';

export default function NewCase() {

    const { session, name, personNumber, bankIdFiles, updateFormFiles, bankIdFormData, bankIdName } = useContext(InfoContext);
    const [validCheck, setValidCheck] = useState(false);
    const [partyType, setPartyType] = useState(null);
    const [insuranceType, setInsuranceType] = useState(null);
    const [loading, setLoading] = useState(false);
    const [isCompleted, setIsCompleted] = useState(false);
    const navigate = useNavigate();

    useEffect(() => {
        if (bankIdName === "") { navigate("/") }
    }, [bankIdName])

    const handleUploadSubmit = async (event) => {

        event.preventDefault();
        if (event.target.checkValidity()) {
            let maildataResult = "", attachmentResult = "";
            const ignoreTimeOffset = () => {
                const timeZoneOffset = (new Date()).getTimezoneOffset() * 60000;
                const localIsoTime = (new Date(Date.now() - timeZoneOffset)).toISOString().slice(0, -1);
                return localIsoTime;
            }
            const date = ignoreTimeOffset();
            const envelopId = `${session.split("-")[0]}${new Date().valueOf()}`;
            setValidCheck(false);
            setLoading(true);
            const maildata = {
                DelegateTo: parseInt(insuranceType),
                DateInserted: date,
                DateReceived: date,
                DateSent: date,
                MailFrom: `${bankIdFormData["epost"]}`,
                MailTo: "firmapost@crawco.no",
                Mailsubject: `Portal skademelding ${bankIdFormData["oppdragsgiver"]}`,
                DelegationInfo: `${bankIdFormData["lossdescription"]} meldt av ${name}`,
                Body: JSON.stringify(`navn: ${name}, personnr: ${personNumber}, mobil: ${bankIdFormData["mobil"]}, lossdescription: ${bankIdFormData["lossdescription"]}, parttype: ${partyType}, oppdragsgiver: ${bankIdFormData["oppdragsgiver"]}`),
                Direction: 'In',
                DocType: 'Webskjema',
                SystemId: "LCS",
                BodyFormat: 1,
                CountAttachment: bankIdFiles ? bankIdFiles?.length : 0,
                InternalComment: envelopId,
                Claimid: "",
                PartyName: name,
                PartyType: partyType,
                Client: bankIdFormData["oppdragsgiver"],
            };
            try {
                maildataResult = await postMaildata(session, maildata);
            } catch {
                navigate("/fail");
            }
            if (bankIdFiles && bankIdFiles?.length > 0) {
                let files = Array.from(bankIdFiles);
                for (let index = 0; index < files.length; index++) {
                    const fileElement = files[index];
                    try {
                        attachmentResult = await postAttachment(session, fileElement, envelopId);
                    } catch {
                        navigate("/fail");
                    }
                }
                updateFormFiles([], "bankIdForm");
            }
            setIsCompleted(true);
            setLoading(false);
        } else {
            const formInvalidElements = event.target.querySelectorAll('input:invalid, textarea:invalid, select:invalid');
            if (formInvalidElements?.length > 0) {
                formInvalidElements[0].focus();
            }
            setValidCheck(true);
            if (!insuranceType) {
                setInsuranceType("");
            }
            if (!partyType) {
                setPartyType("");
            }
            return;
        }
    }

    return (
        <> {loading ? <div className="loading" aria-label="Vennligst vente"></div> :
            !loading && !isCompleted ? <div className="contactFormWrapper">
                <form name="contactForm" onSubmit={(event) => { handleUploadSubmit(event) }} noValidate >
                    <h3 className='medium-header'>Send dokument</h3>
                    <Field item={{ formName: "bankIdForm", name: "navn", type: "text", value: name, required: true, isValidCheck: validCheck, label: "Navn", disabled: true }} />
                    <div>
                        <label className="input-label">
                            <span className="required"> Velg forsikringstype</span>
                            <select id="insuranceType" name="Forsikringstype" required onChange={(event) => { setInsuranceType(event.target.value) }}>
                                <option value="">--- Velg Forsikringstype ---</option>
                                <option value="1004">Bil</option>
                                <option value="1003">Eiendom</option>
                                <option value="1001">Personskade</option>
                                <option value="1006">Kredittkort og forbrukslån</option>
                                <option value="1099">Annet</option>
                            </select>
                        </label>
                        {insuranceType === "" && <p className="error-message">Du må velge en forsikringstype</p>}
                    </div>
                    <Field item={{ formName: "bankIdForm", name: "lossdescription", type: "text", placeholder: "Kort beskrivelse av skaden", required: true, isValidCheck: validCheck, label: "Kort beskrivelse av skaden" }} />
                    <Field item={{ formName: "bankIdForm", name: "oppdragsgiver", type: "text", placeholder: "Navn av Forsikringsselskap", required: true, isValidCheck: validCheck, label: "Forsikringsselskap" }} />
                    <Field item={{ formName: "bankIdForm", name: "polisenr", type: "text", placeholder: "Forsikringsavtalenummer", isValidCheck: validCheck, label: "Forsikringsavtalenummer", pattern: "\d*" }} />
                    <div>
                        <label className="input-label">
                            <span className="required"> Velg hvilken type part du er i saken</span>
                            <select id="parttype" name="parttype" required onChange={(event) => { setPartyType(event.target.value) }}>
                                <option value="">--- Velg parttype ---</option>
                                <option value="forsikringstaker">Forsikringstaker</option>
                                <option value="Kravstiller">Kravstiller</option>
                                <option value="Motpart">Motpart</option>
                                <option value="Annet">Annet</option>
                            </select>
                        </label>
                        {partyType === "" && <p className="error-message">Du må velge en parttype</p>}
                    </div>
                    <Field item={{ formName: "bankIdForm", name: "epost", type: "email", placeholder: "E-post", required: true, isValidCheck: validCheck, label: "Skriv inn gyldig e-post adresse" }} />
                    <Field item={{ formName: "bankIdForm", name: "mobil", type: "text", placeholder: "mobilnummer", label: "Mobilnummer" }} />
                    <Field item={{ formName: "bankIdForm", name: "longdescription", placeholder: "Detaljer og ytterligere beskrivelse", label: "Detaljer og ytterligere beskrivelse", isValidCheck: validCheck }} isTextarea={true} />
                    <br />
                    <p>Trykk for å velge filer eller dra dem til filknappen</p>
                    <FileList item={{ formName: "bankIdForm", text: "Velg fil", name: "attachments", required: false, multiple: true }} />
                    <br />
                    <button type='submit' className='submit-button margin-top__large padding-small'>Send inn</button>
                </form>
                <div className="link-block">
                    <Link to="/">Gå tilbake</Link>
                </div>
            </div> :
                <>
                    <h3 className="medium-header margin-top__large" >Takk. Saken er registrert.</h3>
                    <div className="link-block">
                        <Link to="/">Gå tilbake</Link>
                    </div>
                </>
        }
        </>
    )
}