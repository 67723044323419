
import React, { useContext } from 'react'
import { InfoContext } from './context'
import DefaultHome from './DefaultHome'
import Landing from './Landing'

export default function Home() {

  const { fail, session } = useContext(InfoContext)
  return (
    <>
      {(!session || fail) && <DefaultHome />}
      {session && !fail && <Landing />}
    </>
  )
}
