import React, { useState, useContext, useEffect } from 'react';
import { InfoContext } from '../context'

export default function Filed({ item, isTextarea = false }) {
    const { name, type, placeholder, required = false, disabled = false, label, pattern, value, isValidCheck } = item;
    let { error } = item;
    const [valid, setValid] = useState(false);
    const [currentValue, setCurrentValue] = useState("");
    const { updateFormData, updateLyQuestions } = useContext(InfoContext);

    error = error ?? "Dette feltet er obligatorisk";
    useEffect(() => {
        if (value) {
            setCurrentValue(value);
            setValid(true)
        }
    }, [value])

    const handleChange = (event) => {
        setCurrentValue(event.target.value);
        if (event.target.checkValidity()) {
            setValid(true)
        }
        else {
            setValid(false)
        }
    }

    const handleBlur = (event) => {
        updateFormData(name, event.target.value.trim(), item?.formName)
        if (name.startsWith("lyhelse")) {
            updateLyQuestions(name, label)
        }
    }

    if (!isTextarea) {
        return (
            <div>
                <label className="input-label">
                    <span className={`${required ? "required" : ""}`}> {label}</span>
                    <input value={currentValue} pattern={pattern ?? ".*"} name={name ?? ""} type={type ?? "text"} placeholder={placeholder ?? ""} required={required} disabled={disabled} onChange={(event) => { handleChange(event) }} onBlur={(event) => { handleBlur(event) }} />
                </label>
                {isValidCheck && (required || (value && !valid)) && (!value || !valid) && <p className="error-message" >{error ?? ""}</p>}
            </div>
        )
    } else {
        return (
            <div>
                <label className="input-label">
                    <span className={`${required ? "required" : ""}`}> {label}</span>
                    <textarea value={currentValue} name={name ?? ""} placeholder={placeholder ?? ""} required={required} onChange={(event) => { handleChange(event) }} onBlur={(event) => { handleBlur(event) }}></textarea>
                </label>
                {isValidCheck && (required || (value && !valid)) && (!value || !valid) && <p className="error-message" >{error ?? ""}</p>}
            </div>
        )
    }
}
