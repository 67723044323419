import React, { useState, useContext } from 'react'
import { InfoContext } from './context'
import { Link } from 'react-router-dom';
import crawfordlogo from '../assets/images/crawford.svg';

export default function Navigation() {

    const { name, fail, session, logout } = useContext(InfoContext);
    const [displayProfile, setDisplayProfile] = useState(false);


    const logOutDisplay = (event) => {
        setDisplayProfile(prev => !prev);
    }
    const handleProfileBlur = (event) => {
        if (event.relatedTarget !== null) {
            if (event.relatedTarget.id !== "profile-menu" && event.relatedTarget.parentElement.id !== "profile-menu") {
                setDisplayProfile(false);
            }
        } else {
            setDisplayProfile(false);
        }
    }
    const triggerLogOut = async (event) => {
        logout();
        setDisplayProfile(false);
    }

    return (
        <header>

            <div className='wrapper'>
                <div className='container'>
                    <Link to='/' >
                        <img src={crawfordlogo} className='App-logo' alt='logo' />
                    </Link>
                </div>
            </div>
            {(session && name && !fail) && <div className='profile'>
                <div className='container'>
                    <div className='profile__wrapper'>
                        <button type='button' className={`profile__dropdown ${displayProfile ? 'profile__dropdown__background' : ''}`} onClick={logOutDisplay} onBlur={handleProfileBlur} aria-expanded={`${displayProfile ? true : false}`} aria-controls='profile-menu' aria-label='Meny knapp for profil'>
                        </button>
                        <div role='menu' id='profile-menu' tabIndex={-1} onBlur={handleProfileBlur} className={`profile__menu ${displayProfile ? '' : 'profile__menu__no-display'}`}>
                            <span className='profile__name'>{name}</span>
                            <button type='button' onClick={triggerLogOut}>Logg ut</button>
                        </div>
                    </div>
                </div>
            </div>
            }
        </header>
    )
}
