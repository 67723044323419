import React from "react";


export default function lyHealthSubmit({ lyQuestions }) {

    return (
        <>
            <h2>Vennligst sjekk at svarene dine er riktig</h2>
            {lyQuestions && lyQuestions.map((keyVal, index) => {
                return (<div key={index}>
                    <p className="semiBold">{keyVal[0]}</p>
                    <p className="ly-answer">{keyVal[1]}</p>
                </div>)
            })
            }
        </>
    )
}