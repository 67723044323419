import React from 'react';

export default function Spinner({ title }) {
    return (
        <div className="spinner">
            <div className="spinner__content" role="alert">
                <span aria-hidden="true" className="spinner__animation"></span>
                {title && <h5 className="small-header">{title}</h5>}
            </div>
        </div>
    )
}
