import React from 'react'

export default function Footer() {
    return (
        <footer>
            <div className="contact">
                <p className="lead">Crawford & Company (Norway) AS</p>
                <p> Org nr 957 296 181  | <a href="https://www.crawco.com/no-no">crawco.no</a> | <span className="mobile-display-block">Tlf. <a href="tel:67552500" aria-label="Telefonnummer">67 55 25 00</a></span>
                    {/* <span className="display-block"> Kontaktskjema: <a href="https://crawfordportal.no/kontakt" aria-label="Gå til kontaktskjema."> Gå til kontaktskjema</a></span> */}
                </p>
                <br />
                <p className="copyright">©2024 CRAWFORD & COMPANY (Norway)</p>
            </div>
        </footer>
    )
}

