import React, { useState, useEffect } from 'react';
import './App.scss';
import { getList, deleteToken, getParterList } from './js/api-service';
import Home from './components//Home';
import Header from './components/Header';
import ShowMessage from './components/ShowMessage';
import Error from './components/Error';
import Footer from './components/Footer';
import { Routes, Route, useLocation, useNavigate } from 'react-router-dom';
import { InfoContext } from './components/context';
import Spinner from './components/Spinner';
import Fail from './components/Fail';
import Complete from './components/Complete';
import Contact from './components/Contact';
import Upload from './components/Upload';
import NewCase from './components/NewCase';
import LyHealth from './components/LyHealth/LyHealth';

export default function App() {

  const [session, setSession] = useState(null);
  const [name, setName] = useState(null);
  const [bankIdName, setBankIdName] = useState("");
  const [personNumber, setPersonNumber] = useState();
  const [data, setData] = useState(null);
  const [mailData, setMailData] = useState(null);
  const [parterList, setParterList] = useState(null);
  const [fail, setFail] = useState(false);
  const [error, setError] = useState(false);
  const [logOutSpinner, setLogOutSpinner] = useState(false);
  const [smsConfirm, setSmsConfirm] = useState(false);
  const [contactFormData, setContactFormData] = useState();
  const [bankIdFormData, setBankIdFormData] = useState();
  const [lyHealthFormData, setLyHealthFormData] = useState({});
  const [lyHealthQuestions, setLyHealthQuestions] = useState({});
  const [fullmaktFormData, setFullmaktFormData] = useState();
  const [formFiles, setformFiles] = useState();
  const [bankIdFiles, setBankIdFiles] = useState();
  const [currentCase, setCurrentCase] = useState(0);
  const navigate = useNavigate();
  const location = useLocation();

  const localSession = sessionStorage.getItem("crawford");

  useEffect(() => {
    if (location.pathname.toLocaleLowerCase() === "/kontakt") {
      navigate("/kontakt");
      return;
    }
    else if (location.pathname.toLocaleLowerCase().startsWith("/error")) {
      setError(true);
      return;
    }
    if (location?.state?.session) {
      setSession(location.state.session);
    }
    fetchList();
  }, [location.state])

  const updateSessionStorage = () => {
    sessionStorage?.removeItem("crawford");
    const sessionData = new Date().getTime();
    sessionStorage.setItem("crawford", sessionData);
  }

  const fetchList = async () => {
    const urlParams = new URLSearchParams(location.search);
    let urlSession = session ?? urlParams.get('Session');
    if (urlSession === "error") {
      setError(true);
    }
    else if (urlSession && localSession) {
      urlSession = urlSession.replaceAll("\"", "");
      if (!session)
        setSession(urlSession);
      try {
        const parterResult = await getParterList(urlSession);
        setParterList(parterResult);
        const mailDataResult = await getList(urlSession);
        if (mailDataResult.message.toLowerCase() === "invalid session") {
          setFail(true);
        } else {
          if (mailDataResult.name.includes("--")) {
            setName(mailDataResult.name.split("--")[0]);
            setBankIdName(mailDataResult.name.split("--")[1]);
            setPersonNumber(mailDataResult.personNumber)
          } else {
            setName(mailDataResult.name);
            setBankIdName("");
          }
          let maildata = mailDataResult.data.map(res => res.maildata);
          setData(mailDataResult.data);
          setMailData(maildata);
        }
      } catch (error) {
        setFail(true);
      }
    } else {
      navigate("/");
    }
  }
  const updateFail = (value) => {
    setFail(value);
  }
  const logout = () => {
    setLogOutSpinner(true);
    setSmsConfirm(false);
    handleLogout(true);
  }
  const handleLogout = async (value) => {
    try {
      const deleteMessage = await deleteToken(session);
      if (deleteMessage.toString() === "deleted") {
        setLogOutSpinner(false);
        setSession(null);
        setSmsConfirm(false);
        navigate("/");
        // window.location.href = "/";
      }
    } catch (error) {
      // setFail(true)
      setLogOutSpinner(false);
      setSession(null);
      setSmsConfirm(false);
      navigate("/");
    }
  }
  const updateLyQuestions = (name, label) => {
    let data = { ...lyHealthQuestions[currentCase], [name]: label };
    setLyHealthQuestions({ [currentCase]: data });
  }

  const updateFormData = (name = "", value, formName = "") => {
    if (name.startsWith("lyhelse")) {
      let data = { ...lyHealthFormData[currentCase], [name]: value };
      setLyHealthFormData({ [currentCase]: data });
    }
    else if (formName === "bankIdForm") {
      let data = { ...bankIdFormData, [name]: value };
      setBankIdFormData(data);
    } else if (formName === "fullmaktForm") {
      let data = { ...fullmaktFormData, [name]: value };
      setFullmaktFormData(data);
    } else {
      let data = { ...contactFormData, [name]: value };
      setContactFormData(data);
    }
  }

  const updateFormFiles = (files, formName = "") => {
    if (formName === "bankIdForm") {
      setBankIdFiles(files);
      return;
    }
    setformFiles(files);
  }

  const editDateArchive = (id) => {
    let MailIndex = mailData.findIndex(maildata => maildata.id === id)
    mailData[MailIndex].dateArchive = new Date().toDateString;
  }

  return (
    <>
      <InfoContext.Provider value={{ session, name, bankIdName, personNumber, data, mailData, fail, updateFail, logout, smsConfirm, setSmsConfirm, updateSessionStorage, updateFormData, contactFormData, bankIdFormData, parterList, updateFormFiles, formFiles, bankIdFiles, personNumber, fullmaktFormData, lyHealthFormData, setCurrentCase, currentCase, updateLyQuestions, lyHealthQuestions, setData, setMailData, editDateArchive }}>
        <Header />
        <main className="container">
          {logOutSpinner && <Spinner title="Logging ut" />}
          {fail && <h4 className="normal-header">Det oppsto en feil!</h4>}
          {error && !fail && <Error />}
          {!error && !fail &&
            <Routes >
              <Route path="/" element={<Home />} />
              <Route path="/melding/:id" element={<ShowMessage />} />
              <Route path="/upload/:id" element={<Upload />} />
              <Route path="/ferdig" element={<Complete />} />
              <Route path="/kontakt" element={<Contact />} />
              <Route path="/ny-sak" element={<NewCase />} />
              {/* <Route path="/fullmakt/:id" element={<Attorny />} /> */}
              <Route path="/ly-helse/:id" element={<LyHealth />} />
              <Route path="/fail" element={<Fail />} />
              <Route path="*" element={<Home />} />
            </Routes>
          }
        </main>
        <Footer />
      </InfoContext.Provider>
    </ >
  );
}

