import React, { useRef, useContext } from 'react';
import { InfoContext } from '../context';

export default function RadioGroup({ item }) {
    const { error, name, label, required, show = true, values, value } = item;
    const { updateFormData, updateLyQuestions } = useContext(InfoContext);
    // const [state, setstate] = useState();
    const element = useRef();
    const handleChange = (event) => {
        updateFormData(event.target.name, event.target.value);
        if (name.startsWith("lyhelse")) {
            updateLyQuestions(name, label)
        }
    }

    return (
        <>
            {show && <div ref={element}>
                <p className={`question-header ${required === true || required === "pending" ? "required" : ""}`}>{label}</p>
                {values.map((data, index) => {
                    return (
                        <label key={index} className="radio-label">
                            <input name={name} type="radio" required={index === 0 && required ? true : false} value={data} checked={value === data} onChange={(event) => { handleChange(event) }} />
                            <span className="radio-symbol">{data}</span>
                        </label>
                    )
                })}
                {required && required !== "pending" && !value && <p className="error-message">{error ? error : "Du må velge"}</p>}

            </div>
            }
        </>
    )
}
