import React, { useContext, useEffect, useState } from 'react'
import { InfoContext } from '../context'


export default function CheckboxField({ item }) {

    const { name, label, required = false, isValidCheck, value = false } = item;
    const { updateFormData, updateLyQuestions } = useContext(InfoContext);
    const [valid, setValid] = useState(false);

    const handleCheckboxChange = (event) => {
        updateFormData(name, event.target.checked);
        setValid(event.target.checked);
        if (event.target.name.startsWith("lyhelse")) {
            updateLyQuestions(name, label)
        }
    }


    return (
        <div>
            <label className="checkbox-label">
                <input type="checkbox" name={name} onChange={event => { handleCheckboxChange(event) }} required={required} checked={value} />
                <span className="checkbox-symbol">{label}</span>
            </label>
            {isValidCheck && required && !valid && <p id="error-message" className="error-message">Du må bekrefte dette</p>}
        </div>
    )

}