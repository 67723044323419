import React, { useContext, useEffect, useState } from 'react'
import Field from '../components/custom/Field'
import { Link } from 'react-router-dom'
import { InfoContext } from './context'
import { getToken, postContactForm } from '../js/api-service';

export default function Contact() {

    const { contactFormData, updateFormData } = useContext(InfoContext);
    const [isValidCheck, setIsValidCheck] = useState(false);
    const [insuranceTypeValue, setInsuranceTypeValue] = useState(null);
    const [contactSubmit, setContactSubmit] = useState(false);
    const [loading, setLoading] = useState(false);
    const [fail, setFail] = useState(false);

    useEffect(() => {
        document.body.scrollTop = document.documentElement.scrollTop = 0;
    }, [])

    const handleInsuranceTypeBlur = (event) => {
        updateFormData("Forsikringstype", event.target.value);
        setInsuranceTypeValue(event.target.value);
    }

    const handleUploadSubmit = async (event) => {

        event.preventDefault();
        if (event.target.checkValidity()) {
            setIsValidCheck(false);
            setLoading(true);
        } else {
            setIsValidCheck(true);
            if (insuranceTypeValue === null) {
                setInsuranceTypeValue("");
            }
            return;
        }

        try {
            const token = await getToken();
            if (!token) { setFail(true); return; }
            const contactData = JSON.stringify(contactFormData);
            const postContact = await postContactForm(contactData, token)
            if (postContact === "sent") {
                setContactSubmit(true);
            } else {
                setFail(true);
            }
            setLoading(false);
        } catch {
            setFail(true);
        }
    }

    if (loading) return (<div className="loading" aria-label="Vennligst vente..."></div>)

    return (
        <div className='contactFormWrapper'>
            {fail ? <h4 className='normal-header'>Det oppsto en feil! Vennlighst prøv igjen.</h4> :
                <>
                    {!contactSubmit ?
                        <form name="contactForm" onSubmit={(event) => { handleUploadSubmit(event) }} noValidate>
                            <h3 className='medium-header'>Kontakt oss</h3>
                            <Field item={{ isValidCheck: isValidCheck, name: "email", type: "email", placeholder: "Epost", required: true, label: "Epost" }} />
                            <Field item={{ isValidCheck: isValidCheck, name: "name", type: "text", placeholder: "Navn", required: true, label: "Navn" }} />
                            <div>
                                <label className="input-label">
                                    <span className="required"> Henvendelse-/forsikringstype</span>
                                    <select id="insuranceType" name="Forsikringstype" required onChange={(event) => { handleInsuranceTypeBlur(event) }}>
                                        <option value="">--- Velg Forsikringstype ---</option>
                                        <option value="53">Administrasjon</option>
                                        <option value="1007">Ansvar</option>
                                        <option value="1004">Auto</option>
                                        <option value="1006">Betalingsforsikring</option>
                                        <option value="1005">Boligkjøper</option>
                                        <option value="1003">Eiendom</option>
                                        <option value="1013">Helsevurdering</option>
                                        <option value="352">Personskade</option>
                                        <option value="1006">Reiseforsikring</option>
                                        <option value="36">Salg/Marked</option>
                                    </select>
                                </label>
                                {insuranceTypeValue === "" && <p className="error-message">Du må velge en sykdom type</p>}
                            </div>

                            <Field item={{ isValidCheck: isValidCheck, name: "Forsikringsselskap", type: "text", placeholder: "Forsikringsselskap", required: true, label: "Forsikringsselskap" }} />
                            <Field item={{ isValidCheck: isValidCheck, name: "Polisenummer", type: "text", placeholder: "Polisenummer", label: "Polisenummer" }} />
                            <Field item={{ isValidCheck: isValidCheck, name: "SkadenGjelder", type: "text", placeholder: "Skaden gjelder", label: "Hva gjelder henvendelsen?" }} />
                            <Field item={{ isValidCheck: isValidCheck, name: "kommentar", placeholder: "Skrive din melding...", label: "Melding", required: true }} isTextarea={true} />

                            {isValidCheck && <p className='form-error'>
                                Vennligst fyll ut alle obligatoriske felt!
                            </p>}
                            <br />
                            <button type='submit' className='navigation-button' >Send inn</button>

                            {/* <p className='information'>
                                Dette er en portal for sikker overføring av dokumenter til Crawford &amp; Company ( Norway ) AS.
                                Dokumenter kan lastes opp direkte til vårt system for saksbehandling dersom det allerede har opprettet sak hos oss.
                                Dersom du ønsker å henvende deg til oss uten at sak er opprettet kan du benytte deg av kontaktskjemaet.
                                Overføringen skjer via sikker og kryptert tilkobling (https, og leveres kryptert)
                            </p> */}
                        </form>
                        :
                        <><br /><h3 className="medium-header margin-top__large" >Takk. Vi vil behandle din henvendelse så fort som mulig.</h3></>

                    }
                </>
            }

            <div className="link-block">
                <Link to="/">Gå tilbake</Link>
            </div>
        </div>
    )
}